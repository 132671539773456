import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { VideoCall } from "../components/VideoCall/VideoCall";
import Header from "../components/header";
import { isMobile } from "react-device-detect";
import { getVideoToken, resetVideoRoom } from "../actions/videoCallActions";

export const Lobby = (props: any) => {
  // State for managing Lobby
  const dispatch = useDispatch();
  const history = useHistory();
  const formData = useSelector(
    (state: RootStateOrAny) => state.socketConnection.formData
  );

  const socket = useSelector(
    (state: RootStateOrAny) => state.socketConnection.socket
  );

  const videoToken = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.videoToken
  );
  const searchTerm = useSelector(
    (state: RootStateOrAny) => state.socketConnection.searchTerm
  );

  useEffect(() => {
    // Check to see if this is a page refresh
    if (!searchTerm) {
      // alert(props.history.location.pathname.substr(1));
      history.push(`/${props.history.location.pathname.split("/")[1]}`);
    }
  }, []);

  useEffect(() => {
    dispatch(
      getVideoToken(formData.emailAddress, formData.fullName, searchTerm)
    );
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetVideoRoom());
    };
  }, []);

  return (
    <div>
      {!isMobile ? <Header /> : ""}
      {videoToken ? <VideoCall /> : ""}
    </div>
  );
};
