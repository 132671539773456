import React, { useState } from "react";
import Logo from "../assets/images/version2-images/logo.png";

function Header() {
  return (
    <div className="menu_wrapper">
      <div className="container d-flex justify-content-between">
        <div className="logo-wrapper">
          <a className="navbar-brand" href="#">
            <img src={Logo} alt="logo" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
