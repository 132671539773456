import React, { useEffect, useState } from "react";
import axios from "axios";
import launchToast from "../utils";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { searchRoomWithCode } from "../actions/meetingActions";

type MeetingSearchProps = {
  searchTerm: any;
  setSearchTerm: Function;
};

export const MeetingSearch = ({
  searchTerm,
  setSearchTerm,
}: MeetingSearchProps) => {
  const dispatch = useDispatch();

  const isValidCode = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isValidCode
  );

  useEffect(() => {
    if (searchTerm) {
      const delaySearch = setTimeout(() => {
        dispatch(searchRoomWithCode(searchTerm));
      }, 1500);
      return () => clearTimeout(delaySearch);
    }
    // Axios request
  }, [searchTerm]);

  return (
    <input
      name="meetingCode"
      placeholder="meeting code"
      defaultValue={searchTerm}
      type="text"
      onChange={(e) => setSearchTerm(e.target.value)}
      className={`form-control ${
        isValidCode === true
          ? "code-apply"
          : isValidCode === false
          ? "wrong"
          : ""
      }`}
      id="m-code"
    />
  );
};
