import { combineReducers } from "redux";
import mainReducer from "./mainReducer";
import videoSettings from "./videoSettings";
import socketConnection from "./socketConnection";
import videoCallReducer from "./videoCallReducer";
import { isAsExpression } from "typescript";

const allReducers = combineReducers({
  mainReducer,
  videoSettings,
  socketConnection,
  videoCallReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
