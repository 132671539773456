import React, { useRef, useState, useEffect } from "react";

export const GridParticipant = ({ participant }: any) => {
  const [videoTracks, setVideoTracks] = useState<any>([]);
  const [audioTracks, setAudioTracks] = useState<any>([]);
  const [trackDisable, setTrackDisable] = useState(false);
  const [audioDisable, setAudioDisable] = useState(false);
  const videoRef = useRef<any>();

  const trackpubsToTracks = (trackMap: any) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track: any) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks: any) => [...videoTracks, track]);
        track.attach(videoRef.current);
      } else {
        setAudioTracks((audioTracks: any) => [...audioTracks, track]);
      }

      // if (track.kind === "data") {
      //   track.on("message", (data: any) => {
      //     console.log(data);
      //   });
      // }
    };

    const trackUnsubscribed = (track: any) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks: any) =>
          videoTracks.filter((v: any) => v !== track)
        );
        track.detach();
      } else {
        setAudioTracks((audioTracks: any) =>
          audioTracks.filter((a: any) => a !== track)
        );
      }
    };

    const trackEnabled = (trackPub: any) => {
      // console.log(`TRACK PUB: ${trackPub.kind}`);
      if (trackPub.kind == "video") {
        setTrackDisable(false);
        trackPub.track.attach(videoRef.current);
      } else if (trackPub.kind == "audio") {
        setAudioDisable(false);
        // trackPub.track.attach(audioRef.current);
        // trackPub.track.attach(videoRef.current);
      }
    };

    const trackDisabled = (trackPub: any) => {
      if (trackPub.kind == "video") {
        setTrackDisable(true);
      } else if (trackPub.kind == "audio") {
        setAudioDisable(true);
      }
      // trackPub.track.detach();
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    participant.on("trackEnabled", trackEnabled);
    participant.on("trackDisabled", trackDisabled);
    participant.on("reconnecting", () => {
      console.log("participant is trying to reconnect");
    });
    // console.log(participant);

    return () => {
      // setVideoTracks([]);
      // setAudioTracks([]);
      // participant.removeAllListeners();
      participant.removeListener("trackSubscribed", trackSubscribed);
      participant.removeListener("trackUnsubscribed", trackUnsubscribed);

      participant.removeListener("trackEnabled", trackEnabled);
      participant.removeListener("trackDisabled", trackDisabled);
    };
  }, []);

  useEffect(() => {
    const videoStarted = (track: any) => {
      setVideoTracks((videoTracks: any) => [...videoTracks, track]);
      track.attach(videoRef.current);
    };
    const videoDisabled = (track: any) => {
      setVideoTracks((videoTracks: any) =>
        videoTracks.filter((v: any) => v !== track)
      );
      track.detach();
    };
    const videoEnabled = (track: any) => {
      setVideoTracks((videoTracks: any) => [...videoTracks, track]);
      track.attach(videoRef.current);
    };
    const videoTrack = videoTracks[0];

    if (videoTrack) {
      videoTrack.on("started", videoStarted);
      videoTrack.on("disabled", videoDisabled);
      videoTrack.on("enabled", videoEnabled);
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach(videoRef.current);
        videoTrack.removeAllListeners();
      };
    }
  }, []);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
    }
  }, [videoTracks]);

  return (
    <div className="gridview-call col-lg-4 col-xl-4 col-md-6">
      <div className="grid-img-main">
        <div className="grid-info d-flex justify-content-between align-items-end">
          <h5>{participant.identity.split("$")[1]}</h5>
          {/* <div className="user-call-info mute-unmute">
            <ul>
              <li>
                <a href="#" title="" className="speaker-mute speaker"></a>
                <a href="#" title="" className="speaker-unmute speaker"></a>
              </li>
              <li>
                <a href="#" title="" className="video-mute video-m"></a>
                <a href="#" title="" className="video-unmute video-m"></a>
              </li>
            </ul>
          </div> */}
        </div>
        <video ref={videoRef} autoPlay={true}></video>
      </div>
    </div>
  );
};
