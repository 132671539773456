import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { MeetingSearch } from "../components/meetingSearch";
import Video, { LocalVideoTrack, LocalAudioTrack } from "twilio-video";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Header from "../components/header";
import axios from "axios";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import pollAudio from "../utilities/pollAudio";
import {
  checkIfAcceptedIntoRoom,
  createVideoParticipant,
  enableVideoAndAudioPermissions,
  resetCanJoinCall,
  resetCreateVideoParticipantRequest,
  validateAdminCode,
} from "../actions/meetingActions";
import { VideoSettingsPanel } from "../components/VideoCall/VideoCallComponents/VideoSettingsPanel";
import { attachTracks } from "../utilities/trackFunctions";
import {
  setFormData,
  createSocketConnection,
  setSearchTerm,
  admitIntoCall,
} from "../actions/socketActions";
import { AdminCodeModal } from "../components/AdminCodeModal";

type HomeProps = {
  setSocket: any;
};

export const Home = (props: any) => {
  const dispatch = useDispatch();

  const isValidCode = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isValidCode
  );

  const isMeetingPublic = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isMeetingPublic
  );
  const localAudioTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localAudioTrack
  );

  const localVideoTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localVideoTrack
  );
  const isAdmin = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isAdmin
  );
  const canJoinCall = useSelector(
    (state: RootStateOrAny) => state.mainReducer.canJoinCall
  );
  const roomPk = useSelector(
    (state: RootStateOrAny) => state.mainReducer.roomPk
  );

  // Video/Audio State

  const [modalIsOpen, setIsOpen] = useState(false);
  const [adminCodeOpen, setAdminCodeOpen] = useState(false);
  const [canHear, setCanHear] = useState<boolean>(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [isPartOfMeeting, setIsPartOfMeeting] = useState(false);

  const hasEnabledPermissions = useSelector(
    (state: RootStateOrAny) => state.mainReducer.hasEnabledPermissions
  );

  const [isRequesting, setIsRequesting] = useState(false);

  const { register, handleSubmit, errors, control } = useForm();
  const history = useHistory();
  const videoPreview = useRef(null);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",

      maxHeight: "100vh",
      marginRight: "-50%",

      transform: "translate(-50%, -50%)",
    },
  };

  // Check to see if you are part of call
  useEffect(() => {
    if (isRequesting) {
      const checkIfAvailable = setInterval(() => {
        dispatch(checkIfAcceptedIntoRoom(roomPk, emailAddress));
      }, 4000);
      return () => clearInterval(checkIfAvailable);
    }
  }, [isRequesting]);

  useEffect(() => {
    if (canJoinCall) {
      dispatch(resetCreateVideoParticipantRequest());
      dispatch(admitIntoCall(searchTerm));
      redirectToLobby();
      return () => {
        dispatch(resetCanJoinCall());
      };
    }
  }, [canJoinCall, searchTerm]);

  // Check if the link has meeting code already in it
  useEffect(() => {
    if (props.history.location.pathname) {
      setSearchTerm(props.history.location.pathname.split("/")[1]);
    }
  }, []);

  useEffect(() => {
    // Get query params and see if admin
    const urlParams = new URLSearchParams(window.location.search);
    const adminCode = urlParams.get("admin_code");

    if (adminCode) {
      // Set adminCode in localStorage and remove from url in case they want to share.
      localStorage.setItem("adminCode", adminCode);
      urlParams.delete("admin_code");
      window.history.pushState("name", "", window.location.href.split("?")[0]);
    }
  }, []);

  useEffect(() => {
    // if (localStorage.getItem("adminCode") && searchTerm) {
    //   dispatch(
    //     validateAdminCode(localStorage.getItem("adminCode"), searchTerm)
    //   );
    // }
  }, [searchTerm]);

  // Used to see if someone is in the meeting or not.
  useEffect(() => {
    if (emailAddress && searchTerm) {
      const delaySearch = setTimeout(() => {
        let config: object = {
          method: "get",
          url: `${process.env.REACT_APP_DEV_API}/meet/video-room-participant/${searchTerm}/${emailAddress}/`,
        };

        axios(config)
          .then((res) => setIsPartOfMeeting(true))
          .catch((err) => {
            setIsPartOfMeeting(false);
          });
      }, 1500);
      return () => clearTimeout(delaySearch);
    }
  }, [emailAddress, searchTerm]);

  const redirectToLobby = () => {
    console.log(`/${searchTerm}/lobby/`);

    history.push({
      pathname: `/${searchTerm}/lobby/`,
      state: {
        form: {
          meetingCode: searchTerm,
        },
      },
    });
  };

  const listenForMessage = (e: any) => {
    const response_data = JSON.parse(e.data);
    if (response_data.type === "accept_request_to_meet") {
      console.log(emailAddress);
      console.log(response_data.participant_request[0].participant__email);
      if (
        response_data.participant_request[0].participant__email ==
          emailAddress &&
        response_data.participant_request[0].is_approved == true
      ) {
        redirectToLobby();
      }
    } else {
      return;
    }
  };

  // Used to listen for accept / decline events
  useEffect(() => {
    if (props.socket) {
      props.socket.addEventListener("message", listenForMessage);

      return () => {
        props.socket.removeEventListener("message", listenForMessage);
      };
    }
  }, [props.socket]);

  const onSubmit = (data: any) => {
    dispatch(setFormData(data));
    if (canHear) {
      if (!isAdmin) {
        // const socket: any = new WebSocket(
        //   `${process.env.REACT_APP_DEV_SOCKET}/${searchTerm}/`
        // );

        // socket.onopen = (e: any) => {
        //   props.setSocket(socket);
        //   history.push({
        //     pathname: `/${searchTerm}/waiting-room`,
        //     state: {
        //       form: {
        //         meetingCode: searchTerm,
        //         ...data,
        //       },
        //     },
        //   });
        // };
        dispatch(
          createVideoParticipant({
            room: roomPk,
            email: data.emailAddress,
            name: data.fullName,
            relation: data.relation,
          })
        );
        setIsRequesting(true);
      } else {
        dispatch(admitIntoCall(searchTerm));

        history.push({
          pathname: `/${searchTerm}/lobby/`,
          state: {
            form: {
              meetingCode: searchTerm,
              ...data,
            },
          },
        });
      }
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (localAudioTrack) {
      pollAudio(localAudioTrack, (level: any) => {
        if (level > 1) {
          setCanHear(true);
        }
      });
    }
  }, [localAudioTrack]);

  useEffect(() => {
    if (LocalVideoTrack) {
      attachTracks([localVideoTrack], videoPreview.current);
    }
  }, [localVideoTrack]);

  return (
    <React.Fragment>
      {adminCodeOpen && (
        <AdminCodeModal
          adminCodeOpen={adminCodeOpen}
          setAdminCodeOpen={setAdminCodeOpen}
          searchTerm={searchTerm}
        />
      )}
      <Modal
        style={customStyles}
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <div className="help-box">
          <h2>Can't detect audio? Here are some common tips.</h2>
          <ul>
            <li>
              <b>Have you enabled permissions?</b>
            </li>
            <li>
              <b>On Safari using an iPad or iPhone?</b> Safari won't enable
              audio if you have used audio while Safari has been open. To fix
              this, please exit Safari and restart the application.
            </li>
            <li>
              <b>Is your mic muted?</b> Your mic may be muted by either your
              operating system or a hardware setting on your microphone
            </li>
            <li>
              <b>Is your mic plugged in and working with other applications?</b>{" "}
              Sometimes the mic isn't recognized by the Operating System that
              you are using.
            </li>
            <li>
              <b>Try resetting your device.</b>Resetting your device can
              sometimes cause your mic to be recognized.
            </li>
          </ul>
          <div className="next-step text-right">
            <a
              href="#"
              title="next step"
              className="primary-btn small d-none d-md-block"
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(false);
              }}
            >
              Close
            </a>
          </div>
        </div>
      </Modal>
      <Header />
      <section className="main-bg-sectoin meet-form-main new-meet-sec">
        {!hasEnabledPermissions ? (
          <div className="meet-form white-box">
            <h3>Welcome to Corigan Meet</h3>
            {Video.isSupported ? (
              <div>
                <p>
                  Let's set you up to have a successful meeting. To start,
                  please enable permissions.{" "}
                </p>
                <button
                  className="primary-btn"
                  value="Enable Permissions"
                  onClick={(e) => {
                    dispatch(enableVideoAndAudioPermissions());
                  }}
                >
                  Enable Permissions
                </button>
              </div>
            ) : (
              <p>
                Your current browser doesn't support Web Video. If you are on
                iPhone, please switch over to the Safari app. If on Android,
                please switch to Chrome or Firefox.
              </p>
            )}
          </div>
        ) : (
          <div className="meet-form white-box">
            <h3>Join a meeting</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-label-group">
                <MeetingSearch
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
                <label htmlFor="m-code">Meeting Code</label>
              </div>
              <div className="form-label-group">
                <input
                  type="text"
                  id="inputEmail"
                  className="form-control"
                  placeholder="Email Address"
                  name="emailAddress"
                  onChange={(e) => setEmailAddress(e.target.value)}
                  ref={register({ required: true })}
                  required
                />
                <label htmlFor="inputEmail">Email Address</label>
              </div>
              <div className="form-label-group">
                <input
                  type="text"
                  id="full-name"
                  className="form-control"
                  placeholder="Full Name"
                  required
                  name="fullName"
                  ref={register({ required: true })}
                />
                <label htmlFor="full-name">Full Name</label>
              </div>
              {!isRequesting ? (
                isMeetingPublic ? (
                  isAdmin ? (
                    <input
                      type="submit"
                      className="primary-btn"
                      value="Start Call"
                    >
                      {/* Request to Join */}
                    </input>
                  ) : (
                    <React.Fragment>
                      <input
                        type="submit"
                        className="primary-btn"
                        value="Request to Join"
                      >
                        {/* Request to Join */}
                      </input>
                      <a
                        href="#"
                        title="Admin Sign In"
                        className="primary-btn admin-code-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setAdminCodeOpen(true);
                        }}
                      >
                        Admin Sign In
                      </a>
                    </React.Fragment>
                  )
                ) : isMeetingPublic === false ? (
                  isPartOfMeeting ? (
                    <input
                      type="submit"
                      className="primary-btn"
                      value="Join Meeting"
                    >
                      {/* Request to Join */}
                    </input>
                  ) : (
                    <input
                      type="submit"
                      className="primary-btn"
                      value="Request to Join"
                    >
                      {/* Request to Join */}
                    </input>
                  )
                ) : (
                  ""
                )
              ) : (
                <input
                  type="submit"
                  className="primary-btn requested"
                  value="Requested"
                  disabled
                >
                  {/* Request to Join */}
                </input>
              )}
            </form>
          </div>
        )}
        <div className="test-sound-preview">
          Test sound and preview yourself before meeting.
        </div>
        <div id="video-preview" ref={videoPreview}></div>
      </section>
      {hasEnabledPermissions ? <VideoSettingsPanel /> : ""}
    </React.Fragment>
  );
};
