import React from "react";
import Avatar from "react-avatar";

export const ChatMessage = (props: any) => {
  return (
    <div className="user-chat-main">
      {/* <div className="users-img name-user">jd</div> */}
      <Avatar
        className="users-img name-user"
        size={"26"}
        name={props.message.sent_by}
      />
      <div className="user-chatting">
        <h5>{props.message.sent_by}</h5>
        <p>{props.message.message}</p>
      </div>
    </div>
  );
};
