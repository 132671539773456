import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { ChatMessage } from "./ChatMessage";
// @ts-ignore
import ScrollToBottom from "react-scroll-to-bottom";
import { getWaitingRoomThread } from "../../../../actions/meetingActions";
import {
  setMessages,
  setIsUnreadMessage,
} from "../../../../actions/socketActions";

export const Chat = (props: any) => {
  const textMessage = useRef<any>(null);
  const dispatch = useDispatch();

  const officePk = useSelector(
    (state: RootStateOrAny) => state.mainReducer.officePk
  );
  const thread = useSelector(
    (state: RootStateOrAny) => state.mainReducer.thread
  );
  const officeSlug = useSelector(
    (state: RootStateOrAny) => state.mainReducer.officeSlug
  );

  const socket = useSelector(
    (state: RootStateOrAny) => state.socketConnection.socket
  );
  const searchTerm = useSelector(
    (state: RootStateOrAny) => state.socketConnection.searchTerm
  );
  const localParticipant = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.localParticipant
  );

  const formData = useSelector(
    (state: RootStateOrAny) => state.socketConnection.formData
  );
  const messages = useSelector(
    (state: RootStateOrAny) => state.socketConnection.messages
  );
  const participantName = localParticipant.identity.split("$")[1];

  function listenMessage(e: any) {
    const response_data = JSON.parse(e.data);
    if (response_data.type === "get_all_chat_messages") {
      dispatch(setMessages(JSON.parse(response_data.messages)));
    } else if (response_data.type === "notify_all_users") {
      dispatch(setIsUnreadMessage(true));
    } else {
      return;
    }
  }

  useEffect(() => {
    if (socket) {
      // Create event listener for chat messages
      socket.addEventListener("message", listenMessage);
      return () => {
        socket.removeEventListener("message", listenMessage);
      };
    }
  }, []);

  // On open set unread message to false
  useEffect(() => {
    if (socket) {
      socket.send(
        JSON.stringify({
          type: "GET_CHAT_ROOM",
        })
      );
    }
  }, []);

  return (
    <div role="tabpanel" className="tab-pane fade active show" id="chat">
      <ScrollToBottom className="scrolling">
        {messages
          ? messages.map((message: any) => (
              <ChatMessage key={message.pk} message={message} />
            ))
          : ""}
      </ScrollToBottom>
      <div className="type-msg">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            socket.send(
              JSON.stringify({
                type: "ADD_MESSAGE_TO_CHAT",
                message: textMessage.current.value,
                meet_code: searchTerm,
                sent_by: participantName,
              })
            );
            textMessage.current.value = "";
          }}
        >
          <input
            type="text"
            placeholder="Type your message..."
            className="common-input chat-input"
            ref={textMessage}
          />
          <input type="submit" className="primary-btn" value="Send" />
        </form>
      </div>
    </div>
  );
};
