import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  connectToRoom,
  getVideoRoomRequests,
  participantConnected,
  participantDisconnected,
  removeParticipantFromCurrentCall,
  setDataMessage,
  setDominantSpeaker,
} from "../../actions/videoCallActions";
import { RemoteParticipant, Track } from "twilio-video";
import { VideoCallRight } from "./VideoCallComponents/VideoCallRight";
import { VideoSettings } from "./VideoCallComponents/VideoSettings";
import {
  setAudioDisabled,
  setAudioEnabled,
  setOpenSettings,
  setVideoDisabled,
  setVideoEnabled,
} from "../../actions/videoSettings";
import { DominantSpeaker } from "./VideoCallComponents/DominantSpeaker";
import { GridView } from "./VideoCallComponents/GridView";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const VideoCall = (props: any) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const room = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.room
  );
  const dominantSpeaker = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.dominantSpeaker
  );
  const videoToken = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.videoToken
  );
  const callView = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.callView
  );
  const dataMessage = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.dataMessage
  );
  const openSidebar = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.openSidebar
  );

  const localParticipant = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.localParticipant
  );

  const audioStatus = useSelector(
    (state: RootStateOrAny) => state.videoSettings.audioStatus
  );

  const videoStatus = useSelector(
    (state: RootStateOrAny) => state.videoSettings.videoStatus
  );
  const audioDevice = useSelector(
    (state: RootStateOrAny) => state.videoSettings.audioDevice
  );

  const videoDevice = useSelector(
    (state: RootStateOrAny) => state.videoSettings.videoDevice
  );
  const localVideoTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localVideoTrack
  );
  const searchTerm = useSelector(
    (state: RootStateOrAny) => state.socketConnection.searchTerm
  );
  const socket = useSelector(
    (state: RootStateOrAny) => state.socketConnection.socket
  );

  const localAudioTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localAudioTrack
  );

  const localDataTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localDataTrack
  );

  const openSettings = useSelector(
    (state: RootStateOrAny) => state.videoSettings.openSettings
  );
  const isAdmin = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isAdmin
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      connectToRoom(
        videoToken,
        searchTerm,
        localVideoTrack,
        localAudioTrack,
        localDataTrack
      )
    );
  }, [videoToken]);

  useEffect(() => {
    if (isAdmin) {
      const getAppointmentIntervals = setInterval(() => {
        dispatch(getVideoRoomRequests(searchTerm));
      }, 2000);
      return () => clearInterval(getAppointmentIntervals);
    }
  }, []);

  const muteAudio = () => {
    console.log(localAudioTrack);
    dispatch(setAudioDisabled([localAudioTrack], room.localParticipant));
  };

  useEffect(() => {
    if (room) {
      room.on("participantConnected", (participant: RemoteParticipant) =>
        dispatch(participantConnected(participant))
      );

      room.on("dominantSpeakerChanged", (participant: RemoteParticipant) => {
        dispatch(setDominantSpeaker(participant));
      });

      // room.on("trackDisabled", participantConnected);

      room.on("participantDisconnected", (participant: RemoteParticipant) =>
        dispatch(participantDisconnected(participant))
      );

      room.participants.forEach((participant: RemoteParticipant) => {
        dispatch(participantConnected(participant));
      });

      room.on("trackSubscribed", function (track: Track) {
        track.on("message", function (message: any) {
          dispatch(setDataMessage(message));
        });
      });

      window.addEventListener("beforeunload", () => {
        room.disconnect();
      });
    }
  }, [room]);

  useEffect(() => {
    if (dataMessage) {
      const messageBody = JSON.parse(dataMessage);

      if (messageBody.type === "REMOVE_FROM_CALL") {
        if (
          messageBody.participant.identity === room.localParticipant.identity
        ) {
          room.disconnect();
          const participantEmail = room.localParticipant.identity.split("$")[0];
          dispatch(
            removeParticipantFromCurrentCall(searchTerm, participantEmail)
          );
          history.push("/");
        }
      } else if (messageBody.type === "MUTE_PARTICIPANT") {
        if (
          messageBody.participant.identity === room.localParticipant.identity
        ) {
          // console.log(localAudioTrack);
          muteAudio();
        }
      }
    }
  }, [dataMessage]);

  return (
    <div
      className={`call-main request-submitted request-submitted-new d-flex  ${
        openSidebar ? "close-info" : ""
      }`}
    >
      <Modal isOpen={isOpen} style={customStyles} contentLabel="Invite">
        <h2 className="modal-title">
          Are you sure you want to leave the call?
        </h2>
        <div className="button-container">
          <button
            className="decline-button"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Go Back
          </button>

          <button
            className="accept-button"
            onClick={() => {
              setIsOpen(false);
              room.disconnect();
              socket.close();
              history.push("/");
            }}
          >
            Accept
          </button>
        </div>
      </Modal>
      <div
        className={`call-left meet-call-left ${
          callView === "grid" ? "grid-view" : "spotlight-view"
        }`}
      >
        {callView === "grid" ? (
          <GridView />
        ) : (
          <div className="call-img">
            {dominantSpeaker ? (
              <DominantSpeaker participant={dominantSpeaker} isLocal={false} />
            ) : (
              ""
            )}
          </div>
        )}
        <div className="call-options-main mute-unmute mute-unmute-video">
          <div className="volume-icon">
            {/* <span>Joshua McDonald</span> */}
          </div>
          <div className="user-call-infos">
            <ul>
              <li>
                {!videoStatus ? (
                  <a
                    href="#"
                    title=""
                    className="video-mute video-m"
                    onClick={(e) => {
                      e.preventDefault();
                      room.localParticipant.videoTracks.forEach(
                        (publication: any) => {
                          publication.track.enable();
                        }
                      );
                      dispatch(setVideoEnabled(videoDevice, localParticipant));
                    }}
                  ></a>
                ) : (
                  <a
                    href="#"
                    title=""
                    className="video-unmute video-m"
                    onClick={(e) => {
                      e.preventDefault();
                      room.localParticipant.videoTracks.forEach(
                        (publication: any) => {
                          publication.track.disable();
                        }
                      );
                      dispatch(
                        setVideoDisabled([localVideoTrack], localParticipant)
                      );
                    }}
                  ></a>
                )}
              </li>
              <li>
                {" "}
                <a
                  href="#"
                  title=""
                  className="end-call call-option-img d-none d-md-block"
                  onClick={(e) => {
                    e.preventDefault();

                    setIsOpen(true);
                  }}
                ></a>
              </li>
              <li>
                {!audioStatus ? (
                  <a
                    href="#"
                    title=""
                    className="speaker-mute speaker"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setAudioEnabled(audioDevice, localParticipant));
                    }}
                  ></a>
                ) : (
                  <a
                    href="#"
                    title=""
                    className="speaker-unmute speaker"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        setAudioDisabled([localAudioTrack], localParticipant)
                      );
                    }}
                  ></a>
                )}
              </li>
            </ul>
          </div>
          <div className="call-settings d-none d-md-block">
            {openSettings ? <VideoSettings /> : ""}
            <a
              href="#"
              title=""
              className="setting"
              onClick={(e) => {
                dispatch(setOpenSettings(!openSettings));
              }}
            ></a>
            <div className="setting-info">Joining call with audio</div>
          </div>
        </div>
      </div>
      <VideoCallRight />
    </div>
  );
};
