import React from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Participant, RemoteParticipant } from "twilio-video";
import { ParticipantVoice } from "./ParticipantVoice";
import { LocalParticipant } from "./LocalParticipant";
import { isMobile, isBrowser } from "react-device-detect";
import {
  setCallView,
  setRightTab,
  setSidebar,
  updateVideoRoomRequests,
} from "../../../actions/videoCallActions";
import {
  setVideoEnabled,
  setVideoDisabled,
  setAudioEnabled,
  setAudioDisabled,
} from "../../../actions/videoSettings";
import { Chat } from "./Chat/Chat";
import union from "../../../assets/images/union.svg";
import accept from "../../../assets/images/accept.png";
import deny from "../../../assets/images/deny.png";
import mic from "../../../assets/images/mic-icon.png";
import { ParticipantBox } from "./ParticipantBox";
export const VideoCallRight = (props: any) => {
  const localParticipant = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.localParticipant
  );
  const localVideoTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localVideoTrack
  );
  const localAudioTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localAudioTrack
  );
  const participants = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.participants
  );
  const videoRoomRequests = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.videoRoomRequests
  );
  const rightTab = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.rightTab
  );
  const videoStatus = useSelector(
    (state: RootStateOrAny) => state.mainReducer.videoStatus
  );
  const localDataTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localDataTrack
  );
  const audioStatus = useSelector(
    (state: RootStateOrAny) => state.mainReducer.audioStatus
  );

  const openSidebar = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.openSidebar
  );

  const isAdmin = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isAdmin
  );

  const dispatch = useDispatch();

  return (
    <div
      className={`call-right meet-call-right d-flex ${
        openSidebar ? "open-info" : ""
      }`}
    >
      {participants
        ? participants.map((participant: RemoteParticipant) => (
            <ParticipantVoice participant={participant} />
          ))
        : ""}
      <div className="call-info-tabs">
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className={`nav-link ${rightTab === "chat" ? "active" : ""}`}
              href="#chat"
              role="tab"
              data-toggle="tab"
              onClick={(e) => {
                dispatch(setRightTab("chat"));
              }}
            >
              <span>Office Chat</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${rightTab === "call" ? "active" : ""}`}
              href="#call"
              role="tab"
              data-toggle="tab"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setRightTab("call"));
              }}
            >
              In this call
            </a>
          </li>
        </ul>
        <div className="custom-scrl-content mCustomScrollbar">
          {rightTab === "call" ? (
            <div className="tab-content">
              <div
                role="tabpanel"
                className="tab-pane fade in active call-mobile"
                id="call"
              >
                <div className="custom-scrl-content mCustomScrollbar _mCS_1 mCS_no_scrollbar">
                  <div
                    id="mCSB_1"
                    className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                  >
                    <div
                      id="mCSB_1_container"
                      className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                      dir="ltr"
                    >
                      {isBrowser && (
                        <select
                          name=""
                          id=""
                          className="view-select room-view-opt-main"
                          onChange={(e: any) => {
                            dispatch(setCallView(e.target.value));
                          }}
                        >
                          <option value="grid">Grid View</option>
                          <option value="spotlight">Spotlight View</option>
                        </select>
                      )}
                      {/* <div className="room-view-opt-main">
                        <div className="selected-open-view room-views">
                          <a href="#" title="">
                            Spotlight View{" "}
                            <span>
                              <img
                                src="images/version7-images/spotlight-view.svg"
                                alt=""
                                className="mCS_img_loaded"
                              />
                            </span>
                          </a>
                        </div>
                        <div className="otherviews">
                          <div className="room-views">
                            <a href="#" title="">
                              Grid View{" "}
                              <span>
                                <img
                                  src="images/version7-images/grid-view.svg"
                                  alt=""
                                  className="mCS_img_loaded"
                                />
                              </span>
                            </a>
                          </div>
                          <div className="room-views selected-view-show">
                            <a href="#" title="">
                              Spotlight View{" "}
                              <span>
                                <img
                                  src="images/version7-images/spotlight-view.svg"
                                  alt=""
                                  className="mCS_img_loaded"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                      </div> */}
                      {isAdmin && videoRoomRequests.length > 0 && (
                        <div className="incall-main-info">
                          <h2>PENDING APPLICANTS</h2>
                          <div className="incall-infomain">
                            {videoRoomRequests.map((request: any) => (
                              <div className="incall-bx selected-bx">
                                <div className="user-cll-img">
                                  {/* <img src="images/version7-images/calluser1.png" alt="" className="mCS_img_loaded" > */}
                                </div>
                                <div className="user-cll-info d-flex justify-content-between">
                                  <div className="usernninfo">
                                    <h5>{request.participant.name}</h5>
                                    <p>{request.participant.relation}</p>
                                  </div>
                                  <div className="call-options accept-area">
                                    <ul>
                                      <li>
                                        <a
                                          href="#"
                                          title=""
                                          onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(
                                              updateVideoRoomRequests(
                                                "deny",
                                                request.pk
                                              )
                                            );
                                          }}
                                        >
                                          <img
                                            src={deny}
                                            alt=""
                                            className="mCS_img_loaded"
                                          />
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#"
                                          title=""
                                          onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(
                                              updateVideoRoomRequests(
                                                "accept",
                                                request.pk
                                              )
                                            );
                                          }}
                                        >
                                          <img
                                            src={accept}
                                            alt=""
                                            className="mCS_img_loaded"
                                          />
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      <div className="incall-main-info">
                        <h2>IN CALL</h2>
                        <div className="incall-infomain">
                          {localParticipant && (
                            <div className="incall-bx selected-bx">
                              <div className="user-cll-img">
                                {/* <img src="images/version7-images/calluser1.png" alt="" className="mCS_img_loaded" > */}
                              </div>
                              <div className="user-cll-info d-flex justify-content-between">
                                <div className="usernninfo">
                                  <h5>
                                    {localParticipant.identity.split("$")[1]}{" "}
                                    (Me)
                                  </h5>
                                  {isAdmin && <p>Admin</p>}
                                </div>
                                <div className="calloptions">
                                  <ul>
                                    <li>
                                      <a href="#" title="">
                                        <img
                                          src="images/version7-images/pin-icon.png"
                                          alt=""
                                          className="mCS_img_loaded"
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#" title="">
                                        <img
                                          src="images/version7-images/mic-icon.png"
                                          alt=""
                                          className="mCS_img_loaded"
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#" title="">
                                        {/* <img
                                          src={deny}
                                          alt=""
                                          className="mCS_img_loaded"
                                        /> */}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                          {participants &&
                            participants.map((participant: Participant) => (
                              <ParticipantBox participant={participant} />
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Chat />
          )}
        </div>
        <div className="d-block d-md-none mobile-calls-main">
          <div className="mobile-calls">
            {/* <img src="images/version4-images/mobile-call-img.png" alt="" /> */}
            <div className="call-options-main mute-unmute mute-unmute-video">
              <ul>
                <li>
                  {videoStatus ? (
                    <a
                      href="#"
                      title=""
                      className="video-mute video-m"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setVideoEnabled(localVideoTrack));
                      }}
                    ></a>
                  ) : (
                    <a
                      href="#"
                      title=""
                      className="video-unmute video-m"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setVideoDisabled([localVideoTrack]));
                      }}
                    ></a>
                  )}
                </li>
                <li>
                  {audioStatus ? (
                    <a
                      href="#"
                      title=""
                      className="speaker-mute speaker"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setAudioEnabled(localAudioTrack));
                      }}
                    ></a>
                  ) : (
                    <a
                      href="#"
                      title=""
                      className="speaker-unmute speaker"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setAudioDisabled([localAudioTrack]));
                      }}
                    ></a>
                  )}
                </li>
                <li className="d-md-none">
                  <div className="call-settings">
                    <a
                      href="#"
                      title=""
                      className="setting"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    ></a>
                    <div className="setting-info">Joining call with audio</div>
                  </div>
                </li>
                <li>
                  {" "}
                  <a
                    href="#"
                    title=""
                    className="end-call call-option-img"
                    onClick={(e) => {}}
                  ></a>
                </li>
              </ul>
              <div className="call-settings d-none d-md-block">
                <a href="#" title="" className="setting"></a>
                <div className="setting-info">Joining call with audio</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="openinfo-main">
        <a
          href="#"
          className="clickto-open"
          title=""
          aria-hidden="true"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setSidebar(!openSidebar));
          }}
        >
          <i className="fa fa-angle-left" aria-hidden="true"></i>
          <i className="fa fa-angle-right"></i>
        </a>
        <div className="room-chat-info">
          <ul>
            <li>
              {participants && (
                <a href="#" title="" className="room-count">
                  R/{participants.length + 1}
                </a>
              )}
            </li>
            <li>
              <a href="#" title="" className="msg-count">
                <span>
                  <img src={union} alt="" />
                  <span></span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
