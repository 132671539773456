import axios, { AxiosRequestConfig } from "axios";
import { Action, Dispatch } from "redux";
import Video, {
  LocalVideoTrack,
  LocalAudioTrack,
  LocalDataTrack,
} from "twilio-video";
import { setVideoDevice, setAudioDevice } from "./videoSettings";
import launchToast from "../utils";

function requestSearchRoomWithCode() {
  return {
    type: "REQUEST_SEARCH_ROOM_WITH_CODE",
    isSearchingRoomWithCode: true,
  };
}

function receiveSearchRoomWithCode(
  isValidCode: boolean,
  isMeetingPublic: boolean | null,
  roomPk: number | null
) {
  return {
    type: "RECEIVE_SEARCH_ROOM_WITH_CODE",
    isSearchingRoomWithCode: false,
    isValidCode,
    isMeetingPublic,
    roomPk,
  };
}

function setDevices(devices: Array<MediaDeviceInfo>) {
  return {
    type: "SET_DEVICES",
    devices,
  };
}

function receiveGetWaitingRoomThread(thread: string) {
  return {
    type: "RECEIVE_GET_WAITING_ROOM_THREAD",
    waitingRoomThread: thread,
  };
}

function setVideoDevices(devices: Array<MediaDeviceInfo>) {
  return {
    type: "SET_VIDEO_DEVICES",
    devices,
  };
}

function setAudioDevices(devices: Array<MediaDeviceInfo>) {
  return {
    type: "SET_AUDIO_DEVICES",
    devices,
  };
}

export function setLocalVideoTrack(localVideoTrack: LocalVideoTrack | null) {
  return {
    type: "SET_LOCAL_VIDEO_TRACK",
    localVideoTrack,
  };
}

export function setLocalAudioTrack(localAudioTrack: LocalAudioTrack | null) {
  return {
    type: "SET_LOCAL_AUDIO_TRACK",
    localAudioTrack,
  };
}

export function setLocalDataTrack(localDataTrack: LocalDataTrack) {
  return {
    type: "SET_LOCAL_DATA_TRACK",
    localDataTrack,
  };
}

function setEnabledPermissions(enabledPermissions: boolean) {
  return {
    type: "SET_ENABLED_PERMISSIONS",
    enabledPermissions,
  };
}

function requestValidatingOffice() {
  return {
    type: "REQUEST_VALIDATING_OFFICE",
    isFetchingOffice: true,
    validatingOfficeError: "",
  };
}

function receiveValidatingOffice(
  officeName: string,
  officePk: number,
  slug: string
) {
  return {
    type: "RECEIVE_VALIDATING_OFFICE",
    isFetchingOffice: false,
    officeName,
    officePk,
    slug,
  };
}

function errorValidatingOffice() {
  return {
    type: "ERROR_VALIDATING_OFFICE",
    isFetchingOffice: false,
    validatingOfficeError: "The link you entered isn't valid.",
  };
}

export function enableVideoAndAudioPermissions() {
  return async (dispatch: Dispatch<any>) => {
    await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    const devices = await navigator.mediaDevices.enumerateDevices();
    dispatch(setDevices(devices));
    dispatch(
      setVideoDevices(
        devices.filter(
          (device: MediaDeviceInfo) => device.kind === "videoinput"
        )
      )
    );
    dispatch(
      setAudioDevices(
        devices.filter(
          (device: MediaDeviceInfo) => device.kind === "audioinput"
        )
      )
    );

    const videoDevice = devices.filter(
      (device: any) => device.kind === "videoinput"
    )[0].deviceId;

    const audioDevice = devices.filter(
      (device: any) => device.kind === "audioinput"
    )[0].deviceId;

    dispatch(createLocalTracks(videoDevice, audioDevice));
  };
}

function createLocalTracks(videoId: string, audioId: string) {
  return async (dispatch: Dispatch) => {
    const localVideoTrack = await Video.createLocalVideoTrack({
      deviceId: {
        exact: videoId,
      },
    });

    dispatch(setLocalVideoTrack(localVideoTrack));

    const localAudioTrack = await Video.createLocalAudioTrack({
      deviceId: {
        exact: audioId,
      },
    });
    dispatch(setLocalAudioTrack(localAudioTrack));

    const dataTrack = new LocalDataTrack();

    dispatch(setLocalDataTrack(dataTrack));

    dispatch(setVideoDevice(videoId));
    dispatch(setAudioDevice(audioId));

    dispatch(setEnabledPermissions(true));
  };
}

export function validateOfficeSlug(officeSlug: string) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/office_slug/${officeSlug}`,
  };

  return (dispatch: Dispatch) => {
    dispatch(requestValidatingOffice());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveValidatingOffice(data.name, data.pk, data.slug));
      })
      .catch((err) => {
        dispatch(errorValidatingOffice());
      });
  };
}

export function getWaitingRoomThread(patientPk: number, officeSlug: string) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/guest-waiting-thread/${patientPk}/${officeSlug}/`,
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => dispatch(receiveGetWaitingRoomThread(data)));
  };
}

export function searchRoomWithCode(searchTerm: string) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/meet/video-room/${searchTerm}/`,
  };

  return (dispatch: Dispatch) => {
    dispatch(requestSearchRoomWithCode());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        if (data.public) {
          dispatch(receiveSearchRoomWithCode(true, true, data.pk));
        } else {
          launchToast(
            "This meeting is private. Please enter your email to confirm."
          );
          dispatch(receiveSearchRoomWithCode(true, false, data.pk));
        }
      })
      .catch(() => {
        launchToast(
          `The meeting code you entered isn't valid or doesn't exist. Please try inserting another code to join. `
        );
        dispatch(receiveSearchRoomWithCode(false, null, null));
      });
  };
}

function requestValidateAdminCode() {
  return {
    type: "REQUEST_VALIDATE_ADMIN_CODE",
    isValidatingAdminCode: true,
    isAdmin: false,
    validatingCodeError: false,
  };
}

function receiveValidateAdminCode() {
  return {
    type: "RECEIVE_VALIDATE_ADMIN_CODE",
    isValidatingAdminCode: false,
    isAdmin: true,
  };
}

function errorValidateAdminCode() {
  return {
    type: "ERROR_VALIDATE_ADMIN_CODE",
    isValidatingAdminCode: false,
    isAdmin: false,
    validatingCodeError: true,
  };
}

export function validateAdminCode(
  email: string | null,
  password: string | null,
  videoRoom: string
) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/admin-code-login/`,
    data: {
      email,
      password,
      videoRoom,
    },
  };

  return (dispatch: Dispatch<Action>) => {
    dispatch(requestValidateAdminCode());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveValidateAdminCode());
      })
      .catch((err) => {
        dispatch(errorValidateAdminCode());
      });
  };
}

type CreateMeetingFormRequest = {
  room: string;
  email: string;
  name: string;
  relation: string;
};

export function requestCreateVideoParticipant() {
  return {
    type: "REQUEST_CREATE_VIDEO_PARTICIPANT",
  };
}

export function createVideoParticipant(formData: CreateMeetingFormRequest) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/meet/create-video-participant/`,
    data: {
      room: null,
      email: formData.email,
      name: formData.name,
      relation: formData.relation,
    },
  };

  return (dispatch: Dispatch<any>) => {
    dispatch(requestCreateVideoParticipant());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(createVideoParticipantRequest(data.pk, formData.room));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function receiveCreateVideoParticipantRequest() {
  return {
    type: "RECEIVE_CREATE_VIDEO_PARTICIPANT_REQUEST",
    isRequesting: true,
  };
}

export function resetCreateVideoParticipantRequest() {
  return {
    type: "RESET_CREATE_VIDEO_PARTICIPANT_REQUEST",
    isRequesting: false,
  };
}

export function createVideoParticipantRequest(
  participant: number,
  room: string
) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/meet/create-video-participant-request/`,
    data: {
      participant,
      room,
    },
  };

  return (dispatch: Dispatch<Action>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveCreateVideoParticipantRequest());
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function admitParticipantIntoCall(admitIntoCall: boolean) {
  return {
    type: "ADMIT_PARTICIPANT_INTO_CALL",
    admitIntoCall,
  };
}

export function checkIfAcceptedIntoRoom(room: number, email: string) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/meet/is-accepted-in-room/`,
    data: {
      email,
      room,
    },
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(admitParticipantIntoCall(true));
      })
      .catch(() => {
        dispatch(admitParticipantIntoCall(false));
      });
  };
}

export function resetCanJoinCall() {
  return {
    type: "RESET_CAN_JOIN_CALL",
  };
}
