import React, { useRef, useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { BounceLoader } from "react-spinners";
import { validateAdminCode } from "../actions/meetingActions";

export const AdminCodeModal = (props: any) => {
  const dispatch = useDispatch();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",

      maxHeight: "100vh",
      marginRight: "-50%",

      transform: "translate(-50%, -50%)",
      maxWidth: "300px",
    },
  };

  const email = useRef<any>(null);
  const password = useRef<any>(null);
  const adminCode = useRef<any>(null);
  const isAdmin = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isAdmin
  );
  const isValidatingAdminCode = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isValidatingAdminCode
  );
  const validatingCodeError = useSelector(
    (state: RootStateOrAny) => state.mainReducer.validatingCodeError
  );

  useEffect(() => {
    if (isAdmin) {
      props.setAdminCodeOpen(false);
    }
  }, [isAdmin]);

  return (
    <Modal
      style={customStyles}
      isOpen={props.adminCodeOpen}
      onRequestClose={() => props.setAdminCodeOpen(false)}
    >
      <div className="help-box">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(
              validateAdminCode(
                email.current.value,
                password.current.value,
                props.searchTerm
              )
            );
          }}
        >
          <h2 className="sign-in">Sign in with your Corigan admin account.</h2>
          {/* <div className="form-label-group"> */}
          <input
            type="text"
            name="Email"
            className="form-control"
            style={{ marginBottom: "15px" }}
            placeholder="Email"
            ref={email}
          />
          {/* </div> */}
          {/* <div className="form-label-group"> */}
          <input
            type="password"
            name="password"
            className="form-control"
            style={{ marginBottom: "15px" }}
            placeholder="Password"
            ref={password}
          />
          {/* </div> */}
          {isAdmin && <p>Admin code successful. You can now start the call.</p>}
          {validatingCodeError && (
            <p style={{ color: "red" }}>
              Your login was either not successful or you are not the admin of
              this call. Please try again or request to be admitted.
            </p>
          )}
          {!isAdmin ? (
            !isValidatingAdminCode ? (
              <button className="primary-btn small d-none d-md-block">
                Submit
              </button>
            ) : (
              <BounceLoader />
            )
          ) : (
            <button
              className="primary-btn small d-none d-md-block"
              style={{ opacity: 0.5, cursor: "not-allowed" }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Validated successfully
            </button>
          )}
        </form>
        {/* <div className="next-step text-right">
          <a
            href="#"
            title="next step"
            className="primary-btn small d-none d-md-block"
            onClick={(e) => {
              e.preventDefault();
              props.setAdminCodeOpen(false);
            }}
          >
            Close
          </a>
        </div> */}
      </div>
    </Modal>
  );
};
