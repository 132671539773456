const initialState = {
  socket: null,
  socketStatus: "Disconnected",
  isSubmittingForm: false,
  submittingMessage: "",
  userDetails: null,
  videoRoomName: null,
  hasBeenAdmitted: null,
  formData: {},
  messages: [],
  isUnreadMessage: false,
  searchTerm: "",
};

const socketConnection = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_SOCKET":
      return Object.assign({}, state, {
        socket: action.socket,
      });
    case "SET_SOCKET_STATUS":
      return Object.assign({}, state, {
        socketStatus: action.socketStatus,
      });
    case "RECEIVE_VIDEO_ROOM":
      return Object.assign({}, state, {
        videoRoomName: action.videoRoomName,
      });
    case "IS_SUBMITTING_FORM":
      return Object.assign({}, state, {
        isSubmittingForm: action.isSubmittingForm,
      });
    case "REQUEST_SUBMITTING_FORM":
      return Object.assign({}, state, {
        isSubmittingForm: action.isSubmittingForm,
        submittingMessage: action.submittingMessage,
      });
    case "RECEIVE_SUBMITTING_FORM":
      return Object.assign({}, state, {
        submittingMessage: action.submittingMessage,
        isSubmittingForm: false,
        userDetails: action.userDetails,
      });
    case "ERROR_SUBMITTING_FORM":
      return Object.assign({}, state, {
        isSubmittingForm: action.isSubmittingForm,
        submittingMessage: action.submittingMessage,
      });
    case "REQUEST_SUBMIT_BROWSER_DETAILS":
      return Object.assign({}, state, {
        submittingMessage: action.submittingMessage,
      });
    case "RECEIVE_SUBMIT_BROWSER_DETAILS":
      return Object.assign({}, state, {
        submittingMessage: action.submittingMessage,
      });
    case "ERROR_SUBMIT_BROWSER_DETAILS":
      return Object.assign({}, state, {
        submittingMessage: action.submittingMessage,
        isSubmittingForm: action.isSubmittingForm,
      });
    case "GO_TO_CALL":
      return Object.assign({}, state, {
        hasBeenAdmitted: true,
      });
    case "SET_FORM_DATA":
      return Object.assign({}, state, {
        formData: action.formData,
      });
    case "SET_MESSAGES":
      return Object.assign({}, state, {
        messages: action.messages,
      });
    case "SET_IS_UNREAD_MESSAGE":
      return Object.assign({}, state, {
        isUnreadMessage: action.isUnreadMessage,
      });
    case "SET_SEARCH_TERM":
      return Object.assign({}, state, {
        searchTerm: action.searchTerm,
      });

    default:
      return state;
  }
};

export default socketConnection;
