import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { Participant } from "twilio-video";
import { GridParticipant } from "./Grid/GridParticipant";

export const GridView = (props: any) => {
  const participants = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.participants
  );
  const localParticipant = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.localParticipant
  );
  return (
    <div className="call-img gridview-bg">
      <div className="grid-view-call-main">
        <div className="row">
          {localParticipant && (
            <GridParticipant participant={localParticipant} />
          )}
          {participants.map((participant: Participant) => (
            <GridParticipant participant={participant} />
          ))}
        </div>
      </div>
    </div>
  );
};
