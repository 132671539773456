import React, { useEffect } from "react";
import Header from "../components/header";
import { useHistory } from "react-router-dom";

export const WaitingRoom = (props: any) => {
  const history = useHistory();

  useEffect(() => {
    if (!props.location.state) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (props.socket) {
      props.socket.send(
        JSON.stringify({
          type: "REQUEST_JOIN_ROOM",
          email: props.location.state.form.emailAddress,
          name: props.location.state.form.fullName,
          relation: props.location.state.form.relation.value,
          title: "",
        })
      );

      props.socket.addEventListener("message", listenForMessage);

      return () => {
        props.socket.removeEventListener("message", listenForMessage);
      };
    }
  }, []);

  const listenForMessage = (e: any) => {
    const response_data = JSON.parse(e.data);
    if (response_data.type === "accept_request_to_meet") {
      if (
        response_data.participant_request[0].participant__email ==
          props.location.state.form.emailAddress &&
        response_data.participant_request[0].is_approved == true
      ) {
        history.push({
          pathname: `/${props.location.state.form.meetingCode}/lobby/`,
          state: {
            form: {
              meetingCode: props.location.state.form.meetingCode,
              emailAddress: props.location.state.form.emailAddress,
              fullName: props.location.state.form.fullName,
              relation: props.location.state.form.relation.value,
            },
            videoTrackId: props.location.state.videoTrackId,
            audioTrackId: props.location.state.audioTrackId,
          },
        });
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (props.socket) {
    }
  }, [props.socket]);

  return (
    <React.Fragment>
      <Header />
      <section className="main-bg-sectoin meet-form-main d-flex align-items-center">
        <div className="meet-form white-box">
          <h3>You've requested to join a meeting.</h3>
          <p>
            Do not leave this screen. Once accepted you will be redirected into
            the call.
          </p>
        </div>
      </section>
    </React.Fragment>
  );
};
