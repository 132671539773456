const initialState = {
  hasEnabledPermissions: false,
  devices: [],
  videoDevices: [],
  audioDevices: [],
  localVideoTrack: null,
  localAudioTrack: null,
  localDataTrack: null,
  officeName: "",
  officePk: null,
  officeSlug: null,
  isFetchingOffice: true,
  validatingOfficeError: "",
  thread: null,
  isSearchingRoomWithCode: false,
  isMeetingPublic: null,
  isValidatingAdminCode: false,
  isAdmin: false,
  isRequesting: false,
  roomPk: null,
  canJoinCall: false,
  validatingCodeError: false,
};

const mainReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_DEVICES":
      return Object.assign({}, state, {
        devices: action.devices,
      });
    case "SET_VIDEO_DEVICES":
      return Object.assign({}, state, {
        videoDevices: action.devices,
      });
    case "SET_AUDIO_DEVICES":
      return Object.assign({}, state, {
        audioDevices: action.devices,
      });
    case "SET_LOCAL_VIDEO_TRACK":
      return Object.assign({}, state, {
        localVideoTrack: action.localVideoTrack,
      });
    case "SET_LOCAL_AUDIO_TRACK":
      return Object.assign({}, state, {
        localAudioTrack: action.localAudioTrack,
      });
    case "SET_LOCAL_DATA_TRACK":
      return Object.assign({}, state, {
        localDataTrack: action.localDataTrack,
      });
    case "SET_ENABLED_PERMISSIONS":
      return Object.assign({}, state, {
        hasEnabledPermissions: action.enabledPermissions,
      });
    case "SET_ENABLED_PERMISSIONS":
      return Object.assign({}, state, {
        hasEnabledPermissions: action.enabledPermissions,
      });
    case "REQUEST_VALIDATING_OFFICE":
      return Object.assign({}, state, {
        isFetchingOffice: true,
        validatingOfficeError: "",
      });
    case "RECEIVE_VALIDATING_OFFICE":
      return Object.assign({}, state, {
        isFetchingOffice: false,
        officeName: action.officeName,
        officePk: action.officePk,
        officeSlug: action.slug,
      });
    case "ERROR_VALIDATING_OFFICE":
      return Object.assign({}, state, {
        isFetchingOffice: false,
        validatingOfficeError: "The link you entered isn't valid.",
      });
    case "RECEIVE_GET_WAITING_ROOM_THREAD":
      return Object.assign({}, state, {
        thread: action.waitingRoomThread,
      });
    case "REQUEST_SEARCH_ROOM_WITH_CODE":
      return Object.assign({}, state, {
        isSearchingRoomWithCode: action.isSearchingRoomWithCode,
      });
    case "RECEIVE_SEARCH_ROOM_WITH_CODE":
      return Object.assign({}, state, {
        isSearchingRoomWithCode: action.isSearchingRoomWithCode,
        isValidCode: action.isValidCode,
        isMeetingPublic: action.isMeetingPublic,
        roomPk: action.roomPk,
      });
    case "REQUEST_VALIDATE_ADMIN_CODE":
      return Object.assign({}, state, {
        isValidatingAdminCode: action.isValidatingAdminCode,
        isAdmin: action.isAdmin,
        validatingCodeError: false,
      });
    case "RECEIVE_VALIDATE_ADMIN_CODE":
      return Object.assign({}, state, {
        isValidatingAdminCode: action.isValidatingAdminCode,
        isAdmin: action.isAdmin,
      });
    case "ERROR_VALIDATE_ADMIN_CODE":
      return Object.assign({}, state, {
        isValidatingAdminCode: action.isValidatingAdminCode,
        isAdmin: action.isAdmin,
        validatingCodeError: true,
      });
    case "RECEIVE_CREATE_VIDEO_PARTICIPANT_REQUEST":
      return Object.assign({}, state, {
        isRequesting: true,
      });
    case "RESET_CREATE_VIDEO_PARTICIPANT_REQUEST":
      return Object.assign({}, state, {
        isRequesting: false,
      });
    case "ADMIT_PARTICIPANT_INTO_CALL":
      return Object.assign({}, state, {
        canJoinCall: action.admitIntoCall,
      });
    case "RESET_CAN_JOIN_CALL":
      return Object.assign({}, state, {
        canJoinCall: false,
      });
    default:
      return state;
  }
};

export default mainReducer;
