import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/css/bootstrap.css";
import "./assets/css/custom.css";
import "./assets/css/version2-css/custom.css";
import "./assets/css/version2-css/custom.css";
import "./assets/css/version3-css/custom.css";
import "./assets/css/version4-css/custom.css";
import "./assets/css/version7-css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/version2-css/responsive.css";
import "./assets/css/version3-css/responsive.css";
import "./assets/css/version4-css/responsive.css";
import "./assets/css/version7-css/responsive.css";
import { Home } from "./pages/Home";
import { Lobby } from "./pages/Lobby";
import { WaitingRoom } from "./pages/WaitingRoom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [socket, setSocket] = useState(null);

  toast.configure();
  return (
    <div style={{ height: "100%" }}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            render={(props: any) => (
              <Home {...props} socket={socket} setSocket={setSocket} />
            )}
          />
          <Route
            path="/:meetingCode"
            exact
            component={(props: any) => (
              <Home {...props} socket={socket} setSocket={setSocket} />
            )}
          />
          <Route
            path="/:meetingCode/waiting-room"
            exact
            component={(props: any) => (
              <WaitingRoom {...props} socket={socket} setSocket={setSocket} />
            )}
          />
          <Route
            path="/:meetingCode/lobby"
            exact
            component={(props: any) => <Lobby {...props} socket={socket} />}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
