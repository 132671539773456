import React, { useState, useEffect } from "react";
import { Participant } from "twilio-video";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import deny from "../../../assets/images/deny.png";
import mic from "../../../assets/images/mic-icon.png";
import micoff from "../../../assets/images/mic-off.png";

type ParticipantBoxProps = {
  participant: Participant;
};

export const ParticipantBox = ({ participant }: ParticipantBoxProps) => {
  const [videoTracks, setVideoTracks] = useState<any>([]);
  const [audioTracks, setAudioTracks] = useState<any>([]);
  const [trackDisable, setTrackDisable] = useState(false);
  const [audioDisable, setAudioDisable] = useState(false);

  const trackpubsToTracks = (trackMap: any) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);
  const localDataTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localDataTrack
  );

  const isAdmin = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isAdmin
  );

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track: any) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks: any) => [...videoTracks, track]);
        // track.attach(videoRef.current);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks: any) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track: any) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks: any) =>
          videoTracks.filter((v: any) => v !== track)
        );
        track.detach();
      } else {
        setAudioTracks((audioTracks: any) =>
          audioTracks.filter((a: any) => a !== track)
        );
        setAudioDisable(true);
      }
    };

    const trackEnabled = (trackPub: any) => {
      // console.log(`TRACK PUB: ${trackPub.kind}`);
      if (trackPub.kind == "video") {
        setTrackDisable(false);
        // trackPub.track.attach(videoRef.current);
      } else if (trackPub.kind == "audio") {
        setAudioDisable(false);
        // trackPub.track.attach(audioRef.current);
        // trackPub.track.attach(videoRef.current);
      }
    };

    const trackDisabled = (trackPub: any) => {
      if (trackPub.kind == "video") {
        setTrackDisable(true);
      } else if (trackPub.kind == "audio") {
        setAudioDisable(true);
        console.log("WHATS UP");
      }
      // trackPub.track.detach();
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    participant.on("trackEnabled", trackEnabled);
    participant.on("trackDisabled", trackDisabled);
    // console.log(participant);

    return () => {
      // setVideoTracks([]);
      // setAudioTracks([]);
      // participant.removeAllListeners();
      participant.removeListener("trackSubscribed", trackSubscribed);
      participant.removeListener("trackUnsubscribed", trackUnsubscribed);

      participant.removeListener("trackEnabled", trackEnabled);
      participant.removeListener("trackDisabled", trackDisabled);
    };
  }, []);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    console.log(audioTracks);
    const audioTrackDisabled = () => {
      setAudioDisable(true);
    };

    const audioTrackEnabled = () => {
      // console.log("audio enabled");
      setAudioDisable(false);
    };
    if (audioTrack) {
      // console.log(audioTrack);
      audioTrack.on("disabled", audioTrackDisabled);
      audioTrack.on("enabled", audioTrackEnabled);
      if (audioTrack.isEnabled) {
        setAudioDisable(false);
      } else {
        setAudioDisable(true);
      }
      return () => {
        // console.log("audio track useeffect exit");
        audioTrack.removeListener("disabled", audioTrackDisabled);
        audioTrack.removeListener("enabled", audioTrackEnabled);
      };
    }
  }, [audioTracks]);

  return (
    <div className="incall-bx">
      <div className="user-cll-img">
        <img
          src="images/version7-images/calluser2.png"
          alt=""
          className="mCS_img_loaded"
        />
      </div>
      <div className="user-cll-info d-flex justify-content-between">
        <div className="usernninfo">
          <h5>{participant.identity.split("$")[1]}</h5>
          {/* <p>Doctor</p> */}
        </div>
        <div className="calloptions">
          <ul>
            <li>
              <a href="#" title="">
                <img
                  src="images/version7-images/pin-icon.png"
                  alt=""
                  className="mCS_img_loaded"
                />
              </a>
            </li>
            {!audioDisable ? (
              <li>
                <a
                  href="#"
                  title=""
                  onClick={(e) => {
                    e.preventDefault();
                    if (isAdmin) {
                      localDataTrack.send(
                        JSON.stringify({
                          type: "MUTE_PARTICIPANT",
                          participant: participant,
                        })
                      );
                    }
                  }}
                >
                  <img src={mic} alt="" className="mCS_img_loaded" />
                </a>
              </li>
            ) : (
              <li>
                <a
                  href="#"
                  title=""
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <img
                    src={micoff}
                    alt=""
                    className="mCS_img_loaded"
                    style={{ width: "24px" }}
                  />
                </a>
              </li>
            )}
            {isAdmin && (
              <li>
                <a
                  href="#"
                  title=""
                  onClick={(e) => {
                    e.preventDefault();
                    localDataTrack.send(
                      JSON.stringify({
                        type: "REMOVE_FROM_CALL",
                        participant: participant,
                      })
                    );
                  }}
                >
                  <img src={deny} alt="" className="mCS_img_loaded" />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
